import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import { useHomePageEnabled } from '~/common/hooks/useHomePageEnabled';
import { UserContext } from '~/components/providers/UserProvider';
import { KYCContext } from '~/components/providers/KYCProvider';

import Lobby from './contest-lobby';
import Home from './home';

function SplashPage() {
  const { user, getRedirect, clearRedirect } = useContext(UserContext);
  const { KYCStatus } = useContext(KYCContext);
  const router = useRouter();

  const { isReady, isHomePageEnabled } = useHomePageEnabled();

  useEffect(() => {
    const redirect = getRedirect();

    if (user && KYCStatus) {
      if (redirect !== null) {
        router.replace(redirect); // go to page which redirected to login
        clearRedirect();
      }
    }
  }, [user, KYCStatus, router, clearRedirect, getRedirect]);

  // TODO: add loading state
  if (!isReady) {
    return null;
  }

  return isHomePageEnabled ? <Home /> : <Lobby />;
}

export default SplashPage;

SplashPage.isPublic = true;
